import { useState } from "react";
import "./Controller.css";
const Controller = ({ colorController, colorValue, sizeController, sizeValue, header, widthSize }) => {
  const [show, setShow] = useState(true);
  if (!show) {
    return (
      <div
        className="col-xxl-3 col-xl-3 col-lg-3 col-md-5 col-sm-8 col-8 d-flex flex-column justify-content-center align-items-center shadow-sm m-2 position-relative pointer border toHide"
        style={{height:"25px"}}
        onClick={() => setShow(true)}
      >
        {header}^
      </div>
    );
  }
  return (
    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-5 col-sm-8 col-8 d-flex flex-column justify-content-center align-items-center shadow-sm m-2 position-relative toHide">
      <div className="position-absolute top-0 end-0 pointer" onClick={() => setShow(false)}>
        X
      </div>
      <span className="fs-5 text-muted m-1">{header}</span>
      <div className="col-12 d-flex flex-row">
        <div className="col d-flex flex-row justify-content-center align-items-center">
          <img src="./assets/icons/color.svg" height="30" width="30" />
          <input
            type="color"
            onChange={(e) => colorController(e.target.value)}
            className="form-control form-control-color"
            value={colorValue}
            title="בחר צבע"
          />
        </div>
        {widthSize ? (
          <div className="col d-flex flex-row justify-content-center align-items-center">
            <img src="./assets/icons/font.svg" height="30" width="30" />
            <input
              type="number"
              
              onChange={(e) => sizeController(e.target.value)}
              className="form-control  form-control-color"
              value={`${sizeValue}`}
              title="בחר גודל פונט"
            />
          </div>
        ) : null}
      </div>
      <hr style={{ width: "80%" }} />
    </div>
  );
};

export default Controller;
