import { useState } from "react";
import "./Manager.css";
import Dashboard from "../../components/Dashboard";
import EditInvite from "../../components/EditInvite";
import Settings from "../../components/Settings";

const Manager = ({ addAlertText,docID }) => {
  const [state, setState] = useState("dashboard");
  const [open, setOpen] = useState(false);

  return (
    <div className="col-12 d-flex flex-column position-relative justify-content-center align-items-center" id="navbar">

      <div className={`col-xxl-6 col-xl-6 col-lg-8 col-md-12 col-sm-12 col-12 d-flex flex-row justify-content-center align-items-center border-bottom border-top`} style={{height:"75px"}} >

        <div
          className="col p-2 shadow-sm d-flex flex-row justify-content-center align-items-center greyWhiteHover fs-5 text-center border-bottom rounded  border-dark pointer h-100"
          onClick={() => setState("dashboard")}
        >
         <span className=""> אישורי הגעה</span>
        </div>
        <div
          className="col p-2 shadow-sm d-flex flex-row justify-content-center align-items-center greyWhiteHover fs-5 text-center border-bottom border-end  border-dark pointer h-100"
          onClick={() => setState("edit")}
        >
          <span className="">עיצוב הזמנה</span>
        </div>
        <div
          className="col p-2 shadow-sm d-flex flex-row justify-content-center align-items-center greyWhiteHover fs-5 text-center border-bottom rounde-top-end  border-end  border-dark pointer h-100"
          onClick={() => setState("settings")}
        >
          <span className="">הגדרות</span>
        </div>
      </div>
      <div className="col-12 d-flex justify-content-center align-items-center">
        <View state={state} addAlertText={addAlertText} docID={docID} />
      </div>
      <a className="pointer text-dark p-2 border" onClick={()=>window.open("https://www.flashback.co.il/he/%D7%94%D7%96%D7%9E%D7%A0%D7%94-%D7%93%D7%99%D7%92%D7%99%D7%98%D7%9C%D7%99%D7%AA/rsvp-instructions",'_blank')}>לחצו כאן להדרכה ועזרה</a>

    </div>
  );
};

export default Manager;
const View = ({ state, addAlertText,docID }) => {
  if (state === "dashboard") {
    return <Dashboard addAlertText={addAlertText}docID={docID}/>;
  }
  if (state === "edit") {
    return <EditInvite addAlertText={addAlertText} docID={docID}/>;
  }
  if (state === "settings") {
    return <Settings addAlertText={addAlertText} docID={docID}/>;
  }
};
