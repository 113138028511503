import { useEffect, useState } from "react";
import "./Uploader.css";
import FileDisplay from "../FileDisplay";
const Uploader = ({ withFile, addAlertText, setExternalSource }) => {
  const [localState, setLocalState] = useState("");
  const [source, setSource] = useState("");

  const onUpload = (e) => {
    let localFile = e.target.files[0];
    console.log(e.target.files[0]);
    let fileType = localFile.type;
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      setSource(fileReader.result);
      setLocalState(fileType);
      setExternalSource({ result: fileReader.result, fileType, fileName: localFile.name });
    };
    if (e.target.files[0]) {
      let filesize = e.target.files[0].size / 1048576;
      console.log(filesize);
      if (filesize > 20) {
        addAlertText({ text: "יש להעלות קובץ עד 20 מגה.", mode: "warning" });
        return;
      }
      let splitted = e.target.files[0].type.split("/");
      // console.log(splitted.includes("image"))
      // if(!splitted.includes("video" || "audio"||"image")){
      //   addAlertText({ text: "יש להעלות תמונה, סירטון או שיר.", mode: "warning" });
      //   return;
      // }
      fileReader.readAsDataURL(e.target.files[0]);
    }
  };
  if (source) {
    return <FileDisplay source={source} localState={localState} key="jhndnjksndjksnd" setSource={setSource} withButton={true} />;
  }
  return (
    <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 d-flex flex-column uploadBox justify-content-center align-items-center fs-5 greyBlueText shadow-sm rounded-3 bg-light toHide">
      {withFile ? "לחצו כאן להעלות את קובץ רשימת האורחים" : " לחצו כאן על מנת לבחור תמונה, סירטון או שיר שיתנגנו בפתיחת ההזמנה"}
      {withFile && (
        <a href="#" className="fs-6">
          לחצו כאן על מנת להוריד קובץ לדוגמא
        </a>
      )}
      <img src="./assets/icons/upload.svg" className="" height="50" width="50"></img>
      <input className="form-control col-10 uploader" onChange={(e) => onUpload(e)} type="file" placeholder="בחר קובץ" />
    </div>
  );
};

export default Uploader;
