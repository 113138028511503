import { useEffect, useState } from "react";

const FileDisplay = ({ localState, source, setSource, withButton }) => {
  const [filename, setFilename] = useState("");
  const [clear, setClear] = useState(false);

  useEffect(() => {
    let splitted = localState.split("/");
    setFilename(splitted);
  }, [localState, source]);
  useEffect(() => {
    if (clear) {
      setSource(null);
    }
  }, [clear]);
  if (filename.includes("image")) {
    return (
      <div className="col-12 d-flex flex-column justify-content-center align-items-center">
        <img src={source} className="previewActive rounded" />
        {withButton ? (
          <div onClick={() => setClear(true)} className="pointer">
            לשינוי לחצו כאן
          </div>
        ) : null}
      </div>
    );
  }
  if (filename.includes("video")) {

    return (
      <div className="col-12 d-flex flex-column justify-content-center align-items-center">
        <video autoPlay={true} controls src={source} className="previewActive rounded" />
        {withButton ? (
          <div onClick={() => setClear(true)} className="pointer">
            לשינוי לחצו כאן
          </div>
        ) : null}
      </div>
    );
  }
  if (filename.includes("audio")) {

    return (
      <div className="col-12 d-flex flex-column justify-content-center align-items-center">
        <audio src={source} autoPlay={true} controls className="previewActive rounded" />
        {withButton ? (
          <div onClick={() => setClear(true)} className="pointer">
            לשינוי לחצו כאן
          </div>
        ) : null}{" "}
      </div>
    );
  }
  return (
    <div className="col-12 d-flex flex-column justify-content-center align-items-center">
      <span className="">הקובץ אינו נתמך. אנא בחרו קובץ מתאים.</span>
    
        <div onClick={() => setClear(true)} className="pointer border border-warning p-2 rounded">
          לבחירת קובץ אחר לחצו כאן
        </div>
    </div>
  );

};

export default FileDisplay;
