import { isEmpty } from "@firebase/util";
import { useEffect, useState } from "react";
import Controller from "../Controller";
import PreviewBtn from "../PreviewBtn";
import Uploader from "../Uploder";
import "./EditInvite.css";
import { getStorage, ref, uploadString } from "firebase/storage";
import { addDocumentData, getDocument } from "../../firebase";
import { uuidv4 } from "@firebase/util";
import FileDisplay from "../FileDisplay";
import Waze from "../Waze";

const EditInvite = ({ addAlertText, docID }) => {
  const [namesFontSize, setNamesFontSize] = useState("50");
  const [namesColor, setNamesColor] = useState("#D3AF37");
  const [quouteFontSize, setQuouteFontSize] = useState("28");
  const [quouteColor, setQuouteColor] = useState("#D3AF37");
  const [dateFontSize, setDateFontSize] = useState("50");
  const [dateColor, setDateColor] = useState("#D3AF37");
  const [addressFontSize, setAddressFontSize] = useState("20");
  const [addressColor, setAddressColor] = useState("#D3AF37");
  const [parentsFontSize, setParentsFontSize] = useState("24");
  const [parentsColor, setParentsColor] = useState("#D3AF37");
  const [backgroundColor, setBackgroundColor] = useState("#FFFEFC");
  const [borderColor, setBorderColor] = useState("#263F3F");
  const [externalSource, setExternalSource] = useState({});
  const [submit, setSubmit] = useState(false);
  const [eventOwners, seteventOwners] = useState("כותרת האירוע שלכם!");
  const [dedicationText, setDedicationText] = useState("טקסט טקסט טקסט  ");
  const [date, setDate] = useState("27.08.22");
  const [day, setDay] = useState("יום חמישי");

  const [locationName, setLocationName] = useState(" אולמי השרון");
  const [locationAddress, setLocationAddress] = useState("רחוב החלוצים 5 רעננה");
  const [brideParentsLabel, setBrideParentsLabel] = useState("אחים");
  const [groomParentsLabel, setGroomParentsLabel] = useState("הורים");
  const [brideParentsNames, setBrideParentsNames] = useState("יצחק ורבקה ימיני");
  const [groomParentsNames, setGroomParentsNames] = useState(" יעקב ורחל ישראלי");
  const [dedicationSecond, setdedicationSecond] = useState(" נשמח לראותכם!");
  const [previewMode, setPreviewMode] = useState(false);
  const [attachmentUrl, setAttachmentUrl] = useState("");
  const [attachmentType, setAttachmentType] = useState("");
  const [withWazeLink, setWithWazeLink] = useState(false);
  const [namesCols, setNamesCols] = useState(1);
  const [dedicationCols, setDedicationCols] = useState(1);
  const [dateCols, setDateCols] = useState(1);
  const [dayCols, setDayCols] = useState(1);
  const [loctionNameCols, setLoctionNameCols] = useState(1);
  const [LocationAddressCols, setLocationAddressCols] = useState(1);
  const [groomLabelCols, setgroomLabelCols] = useState(1);
  const [brideLabelCols, setBrideLabelCols] = useState(1);
  const [brideParentsNamesCols, setbrideParentsNamesCols] = useState(1);
  const [groomParentsNamesCols, setgroomParentsCols] = useState(1);
  const [dedicationSecondCols, setdedicationSecondCols] = useState(1);

  useEffect(() => {
    const fireAsync = async () => {
      let doc = await getDocument({ docID: docID });
      let data = doc.data();
      console.log(data);
      if (data.hasOwnProperty("invitationData")) {
        const invitationData = data.invitationData;
        setNamesFontSize(invitationData.namesFontSize);
        setNamesColor(invitationData.namesColor);
        setQuouteFontSize(invitationData.quouteFontSize);
        setQuouteColor(invitationData.quouteColor);
        setDateFontSize(invitationData.dateFontSize);
        setDateColor(invitationData.dateColor);
        setAddressFontSize(invitationData.addressFontSize);
        setAddressColor(invitationData.addressColor);
        setParentsFontSize(invitationData.parentsFontSize);
        setParentsColor(invitationData.parentsColor);
        setBackgroundColor(invitationData.backgroundColor);
        setBorderColor(invitationData.borderColor);
        seteventOwners(`${invitationData.eventOwners}`);
        setDedicationText(invitationData.dedicationText);
        setDate(invitationData.date);
        setDay(invitationData.day);
        setLocationName(invitationData.locationName);
        setLocationAddress(invitationData.locationAddress);
        setBrideParentsLabel(invitationData.brideParentsLabel);
        setGroomParentsLabel(invitationData.groomParentsLabel);
        setBrideParentsNames(invitationData.brideParentsNames);
        setGroomParentsNames(invitationData.groomParentsNames);
        setdedicationSecond(invitationData.dedicationSecond);
        setWithWazeLink(invitationData.withWazeLink);
        setBrideLabelCols(invitationData.brideLabelCols);
        setbrideParentsNamesCols(invitationData.brideParentsNamesCols);
        setdedicationSecondCols(invitationData.dedicationCols);
        if (invitationData.hasOwnProperty("attachmentData")) {
          setAttachmentUrl(invitationData.attachmentData.url);
          setAttachmentType(invitationData.attachmentData.fileType);
        }
      }

      return doc;
    };
    if (docID) {
      fireAsync();
    }
  }, [docID]);

  useEffect(() => {
    if (submit) {
      const payload = {
        invitationData: {
          eventOwners,
          dedicationSecond,
          dedicationText,
          date,
          day,
          locationAddress,
          locationName,
          brideParentsLabel,
          brideParentsNames,
          groomParentsLabel,
          groomParentsNames,
          namesFontSize,
          namesColor,
          quouteFontSize,
          quouteColor,
          dateFontSize,
          dateColor,
          addressFontSize,
          addressColor,
          parentsFontSize,
          parentsColor,
          backgroundColor,
          borderColor,
          withWazeLink,
        },
      };

      const storage = getStorage();
      const uuid = uuidv4();
      const fireAsync = async () => {
        if (!isEmpty(externalSource)) {
          let splittedName = externalSource.fileName.split(".");
          splittedName = splittedName[splittedName.length - 1];
          const storageRef = ref(storage, `${uuid}.${splittedName}`);
          const snapshot = await uploadString(storageRef, externalSource.result, "data_url");
          let url = `https://firebasestorage.googleapis.com/v0/b/invitations778.appspot.com/o/${encodeURIComponent(snapshot.metadata.fullPath)}?alt=media`;
          payload.invitationData.attachmentData = {
            url,
            fileName: snapshot.metadata.fullPath,
            fileType: snapshot.metadata.contentType,
          };
        }
        if (isEmpty(externalSource)) {
          payload.invitationData.attachmentData = {
            url: attachmentUrl,
            fileType: attachmentType,
          };
        }
        const documentData = await addDocumentData({ docID: docID, payload: payload });
        addAlertText({ text: "השינויים נשמרו בהצלחה.", mode: "success" });
        setSubmit(false);
      };

      fireAsync();
    }
  }, [submit]);

  useEffect(() => {
    function autoResize() {
      this.style.height = "auto";
      this.style.height = this.scrollHeight + "px";
    }
    let texts = document.querySelectorAll("textarea");
    texts.forEach((el) => {
      el.addEventListener("input", autoResize, false);
    });

    return () => {
      texts.forEach((el) => {
        el.removeEventListener("input", () => {}, false);
      });
    };
  }, []);
  return (
    <div
      className="col-xxl-10 col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 rounded d-flex flex-column greyBlue position-relative justify-content-center align-items-center "
      style={{ borderLeft: `10px solid ${borderColor}`, borderRight: `10px solid ${borderColor}`, backgroundColor: backgroundColor, overflowX: "hidden" }}
    >
      <PreviewBtn isPreview={previewMode} submit={submit} setPreview={setPreviewMode} setSubmit={setSubmit} key="32487u8924374892xnjckvnjkxcnvkxcnv" />
      <div className="col-11 d-flex flex-row justify-content-center m-2">
        <Controller
          widthSize={false}
          colorController={setBackgroundColor}
          colorValue={backgroundColor}
          key="jasnjknsjnausjnaujnsuasdasdasdasdasfsiaui"
          header={"צבע ההזמנה"}
        />
        <Controller
          widthSize={false}
          colorController={setBorderColor}
          colorValue={borderColor}
          key="jasnjknsjnausjndfdgdgfsdfghsdfghaujnsuiaui"
          header={"צבע שוליים"}
        />
      </div>
      {/* upload */}
      <Waze withWazeLink={withWazeLink} setWithWazeLink={setWithWazeLink} />
      <div className="col-10 d-flex flex-column justify-content-center align-items-center  mt-2 position-relative ">
        {attachmentUrl ? (
          <FileDisplay source={attachmentUrl} localState={attachmentType} setSource={setAttachmentUrl} withButton={true} />
        ) : (
          <Uploader
            withFile={false}
            addAlertText={addAlertText}
            key="smmdkmsklkbnklkljsnkldnsklmksdl"
            setExternalSource={setExternalSource}
            externalSource={externalSource}
          />
        )}
      </div>
      {/* marriage info */}
      <div className="col-10 d-flex flex-column justify-content-center align-items-center mt-2 position-relative text-center">
        <hr className="rounded" style={{ width: "80%", backgroundColor: borderColor, height: "5px", opacity: "0.2" }} />
        <Controller
          widthSize={false}
          colorController={setNamesColor}
          colorValue={namesColor}
          sizeController={setNamesFontSize}
          sizeValue={namesFontSize}
          key="jasnjknsjnausjnaujnsuiaui"
          header={"חוגג/חוגגים"}
        />

        <div className="col-10 d-flex flex-row  justify-content-center align-items-center">
          <textarea
            contentEditable={true}
            className="col-12 formItem text-center text-center names text-center"
            onChange={(e) => {
              seteventOwners(`${e.target.value}`);
              return;
            }}
            // onKeyDown={(e) => {
            //   if (e.key === "Enter") {
            //     setNamesCols((prev) => prev + 1);
            //   }
            //   if (e.key === "Backspace") {
            //     if (e.target.rows !== 1) {
            //       setNamesCols((prev) => prev - 1);
            //     }
            //   }
            // }}
            // rows={"2"}
            style={{ color: `${namesColor}`, backgroundColor: "transparent", border: "none", whiteSpace: "pre-wrap", height: "75px" }}
            key="lnsdjknasjkddnajksndjkasndjkasdjkas"
            disabled={previewMode ? true : false}
            id="owners"
            value={eventOwners}
          ></textarea>
        </div>
      </div>
      <div className="col-10 d-flex flex-column justify-content-center align-items-center mt-2">
        <Controller
          widthSize={true}
          colorController={setQuouteColor}
          colorValue={quouteColor}
          sizeController={setQuouteFontSize}
          sizeValue={quouteFontSize}
          key="kmjaklsmklasmklasmklamskl"
          header={"הקדשה לאורחים"}
        />

        <textarea
          // onKeyDown={(e) => {
          //   if (e.key === "Enter") {
          //     setDedicationCols((prev) => prev + 1);
          //   }
          //   if (e.key === "Backspace") {
          //     if (e.target.rows !== 1) {
          //       setDedicationCols((prev) => prev - 1);
          //     }
          //   }
          // }}
          // rows={"2"}
          contentEditable={true}
          className="col-12 text-center"
          style={{
            color: `${quouteColor}`,
            fontSize: `${parseInt(quouteFontSize) - 5}px`,
            backgroundColor: "transparent",
            border: "none",
            whiteSpace: "pre-wrap",
          }}
          key="lnsdjknasjkddnajksndjkasndjkasadassdjkas"
          disabled={previewMode ? true : false}
          onChange={(e) => {
            setDedicationText(e.target.value);
          }}
          value={dedicationText}
        ></textarea>
      </div>
      {/* date */}
      <div className="col-10 d-flex flex-column justify-content-center align-items-center">
        <Controller
          widthSize={true}
          colorController={setDateColor}
          colorValue={dateColor}
          sizeController={setDateFontSize}
          sizeValue={dateFontSize}
          key="kmjaklsmklasmkldsdsdsdsasmklamskl"
          header={"תאריך"}
        />
        <textarea
          // onKeyDown={(e) => {
          //   if (e.key === "Enter") {
          //     setDateCols((prev) => prev + 1);
          //   }
          //   if (e.key === "Backspace") {
          //     if (e.target.rows !== 1) {
          //       setDateCols((prev) => prev - 1);
          //     }
          //   }
          // }}
          // rows={"2"}
          contentEditable={true}
          className="col-12 formItem text-center "
          style={{
            color: `${dateColor}`,
            fontSize: `${dateFontSize}px`,
            letterSpacing: "10px",
            backgroundColor: "transparent",
            border: "none",
            whiteSpace: "pre-wrap",
            height: "75px",
          }}
          key="lnsdjknasjkddnajksndjkassadasdasdndjkadsfsdfsdjkas"
          disabled={previewMode ? true : false}
          value={date}
          onChange={(e) => setDate(e.target.value)}
        ></textarea>
        <textarea
          // onKeyDown={(e) => {
          //   if (e.key === "Enter") {
          //     setDayCols((prev) => prev + 1);
          //   }
          //   if (e.key === "Backspace") {
          //     if (e.target.rows !== 1) {
          //       setDayCols((prev) => prev - 1);
          //     }
          //   }
          // }}
          // rows={"2"}
          contentEditable={true}
          className="col-12 formItem text-center text-center text-center"
          style={{
            color: `${dateColor}`,
            fontSize: `${parseInt(dateFontSize) - 15}px`,
            backgroundColor: "transparent",
            border: "none",
            whiteSpace: "pre-wrap",
          }}
          key="lnsdjknasjkddnajksndjkasdasdasndjkadsfsdfsdfsdjkas"
          disabled={previewMode ? true : false}
          value={day}
          onChange={(e) => setDay(e.target.value)}
        ></textarea>
      </div>
      <div className="col-10 d-flex flex-column justify-content-center align-items-center">
        {/* <Controller
          widthSize={true}
          colorController={setAddressColor}
          colorValue={addressColor}
          sizeController={setAddressFontSize}
          sizeValue={addressFontSize}
          key="kmjaklsmklasmsdfsdfsdfksdfsdfsdfldsdsdsdsasmklamskl"
          header={"מיקום האירוע"}
        /> */}

        <textarea
          // onKeyDown={(e) => {
          //   if (e.key === "Enter") {
          //     setLoctionNameCols((prev) => prev + 1);
          //   }
          //   if (e.key === "Backspace") {
          //     if (e.target.rows !== 1) {
          //       setLoctionNameCols((prev) => prev - 1);
          //     }
          //   }
          // }}
          // rows={"2"}
          contentEditable={true}
          className="col-12 formItem text-center text-center"
          style={{ color: `${addressColor}`, fontSize: `${addressFontSize}px`, backgroundColor: "transparent", border: "none", whiteSpace: "pre-wrap" }}
          key="lnsdjknasjkddnajksndjkasdasdfsdfsdfsdasdndjkasdjkas"
          disabled={previewMode ? true : false}
          value={locationName}
          onChange={(e) => setLocationName(e.target.value)}
        ></textarea>
        <textarea
          // onKeyDown={(e) => {
          //   if (e.key === "Enter") {
          //     setLocationAddressCols((prev) => prev + 1);
          //   }
          //   if (e.key === "Backspace") {
          //     if (e.target.rows !== 1) {
          //       setLocationAddressCols((prev) => prev - 1);
          //     }
          //   }
          // }}
          // rows={"2"}
          contentEditable={true}
          className="col-12 formItem text-center text-center"
          style={{ color: `${addressColor}`, fontSize: `${addressFontSize}px`, backgroundColor: "transparent", border: "none", whiteSpace: "pre-wrap" }}
          key="lnsdjknasjkddnajksndjkasnassdfsdfsdfasdasddjkasdjkas"
          disabled={previewMode ? true : false}
          value={locationAddress}
          onChange={(e) => setLocationAddress(e.target.value)}
        ></textarea>
      </div>
      {/* parents info */}
      <div className="col-10 d-flex flex-column justify-content-center align-items-center mt-3 position-relative">
        {/* <Controller
          widthSize={true}
          header={"ההורים"}
          colorController={setParentsColor}
          colorValue={parentsColor}
          sizeController={setParentsFontSize}
          sizeValue={parentsFontSize}
          key="kmjaklsmasdasdasdklasmsdfsdfsdfkldsdsdsdsasmklamskl"
        /> */}

        <div className="col-12 d-flex flex-row flex-wrap justify-content-center align-items-start" style={{ minHeight: "100px", whiteSpace: "pre-wrap" }}>
          <div className="col-5 d-flex flex-column justify-content-start align-items-center border-start">
            <textarea
              // onKeyDown={(e) => {
              //   if (e.key === "Enter") {
              //     setgroomLabelCols((prev) => prev + 1);
              //   }
              //   if (e.key === "Backspace") {
              //     if (e.target.rows !== 1) {
              //       setgroomLabelCols((prev) => prev - 1);
              //     }
              //   }
              // }}
              // rows={"2"}
              contentEditable={true}
              key="lnsdjknasjkddnajksndjkasdasdasdasndjkasdjkas"
              disabled={previewMode ? true : false}
              className=" greyBlueText text-center"
              style={{
                color: `${parentsColor}`,
                fontSize: `${parseInt(parentsFontSize) + 10}px`,
                backgroundColor: "transparent",
                border: "none",
                whiteSpace: "pre-wrap",
              }}
              value={groomParentsLabel}
              onChange={(e) => setGroomParentsLabel(e.target.value)}
            ></textarea>
            <div className="col-12 d-flex flex-row justify-content-start align-items-center">
              <textarea
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setgroomParentsCols((prev) => prev + 1);
                  }
                  if (e.key === "Backspace") {
                    if (e.target.rows !== 1) {
                      setgroomParentsCols((prev) => prev - 1);
                    }
                  }
                }}
                rows={groomParentsNamesCols}
                contentEditable={true}
                className="col-12 formItem text-center text-center"
                style={{
                  color: `${parentsColor}`,
                  fontSize: `${parseInt(parentsFontSize) - 5}px`,
                  backgroundColor: "transparent",
                  border: "none",
                  whiteSpace: "pre-wrap",
                }}
                key="lnsdjknasjkddnajksndjkaasdasdasdasdsndjkasdjkas"
                disabled={previewMode ? true : false}
                value={groomParentsNames}
                onChange={(e) => setGroomParentsNames(e.target.value)}
              ></textarea>
            </div>
          </div>

          <div className="col-5 d-flex flex-column justify-content-start align-items-center">
            <textarea
              // onKeyDown={(e) => {
              //   if (e.key === "Enter") {
              //     setBrideLabelCols((prev) => prev + 1);
              //   }
              //   if (e.key === "Backspace") {
              //     if (e.target.rows !== 1) {
              //       setBrideLabelCols((prev) => prev - 1);
              //     }
              //   }
              // }}
              // rows={"2"}
              contentEditable={true}
              key="zxczxczxcasdasda"
              disabled={previewMode ? true : false}
              className=" greyBlueText text-center"
              style={{
                color: `${parentsColor}`,
                fontSize: `${parseInt(parentsFontSize) + 10}px`,
                backgroundColor: "transparent",
                border: "none",
                whiteSpace: "pre-wrap",
              }}
              value={brideParentsLabel}
              onChange={(e) => setBrideParentsLabel(e.target.value)}
            ></textarea>
            <div className="col-12 d-flex flex-row justify-content-start align-items-center">
              <textarea
                // onKeyDown={(e) => {
                //   if (e.key === "Enter") {
                //     setbrideParentsNamesCols((prev) => prev + 1);
                //   }
                //   if (e.key === "Backspace") {
                //     if (e.target.rows !== 1) {
                //       setbrideParentsNamesCols((prev) => prev - 1);
                //     }
                //   }
                // }}
                // rows={"2"}
                contentEditable={true}
                className="col-12 formItem text-center text-center"
                style={{
                  color: `${parentsColor}`,
                  fontSize: `${parseInt(parentsFontSize) - 5}px`,
                  backgroundColor: "transparent",
                  border: "none",
                  whiteSpace: "pre-wrap",
                }}
                key="lnsdjknasjkddnajksndjkasndasdasdasdjkasdjkas"
                disabled={previewMode ? true : false}
                value={brideParentsNames}
                onChange={(e) => setBrideParentsNames(e.target.value)}
              ></textarea>
            </div>
          </div>
        </div>
        <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10 d-flex flex-row justify-content-center align-items-center mt-2">
          <textarea
            contentEditable={true}
            className="col-12 text-center"
            key="lnsdjknasjkddnajkszxczxczxndjkשדגשדגasndjkasdjkas"
            disabled={previewMode ? true : false}
            value={dedicationSecond}
            onChange={(e) => {
              setdedicationSecond(e.target.value);
            }}
            style={{
              backgroundColor: "transparent",
              border: "none",
              fontSize: `${parseInt(parentsFontSize) + 10}px`,
              color: `${parentsColor}`,
              whiteSpace: "pre-wrap",
            }}
          ></textarea>
        </div>
      </div>

      <PreviewBtn
        submit={submit}
        setPreview={setPreviewMode}
        isPreview={previewMode}
        setSubmit={setSubmit}
        key="32487u89243asdasdasdasdaf74892xnjckvnjkxcnvkxcnv"
      />
    </div>
  );
};

export default EditInvite;
