import axios from "axios";

const { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword,linkWithCredential, sendPasswordResetEmail } = require("firebase/auth");

const { initializeApp } = require("firebase/app");
const {
  getFirestore,

  getDoc,
  doc,
  setDoc,
  collection,
  updateDoc,
  arrayUnion,
  arrayRemove,
} = require("firebase/firestore/lite");

const firebaseConfig = {
  apiKey: "AIzaSyCFRG9Hzt4QaSM5jSKGbi77JU0jDog7-48",
  authDomain: "invitations778.firebaseapp.com",
  projectId: "invitations778",
  storageBucket: "invitations778.appspot.com",
  messagingSenderId: "1086400457897",
  appId: "1:1086400457897:web:a0ae55369fcaf5219e0db8",
  measurementId: "G-RLVRFXR1J5",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export const createEventUser = async ({ username, password,payload }) => {
  const res = await createUserWithEmailAndPassword(auth, username, password).catch((err) => console.log(err));
  if (res) {
    let docID = res._tokenResponse.localId;
    let docsref = doc(db, "users", docID);
    await setDoc(docsref, payload).catch((err) => console.log(err));
  }
  return res;
};
export const createUser = async ({ username, password }) => {
  const res = await createUserWithEmailAndPassword(auth, username, password).catch((err) => console.log(err));
  return res;
};
export const login = async ({ username, password }) => {
  const res = await signInWithEmailAndPassword(auth, username, password).catch((err) => console.log(err));
  return res;
};

export { db, app, auth };

export const addDocumentData = async ({ docID, payload }) => {
  let docsref = doc(db, "users", docID);
  const res = await updateDoc(docsref, payload).catch((err) => console.log(err));
  console.log(res);
  return res;
};
export const addContactsSpecificData = async ({ docID, payload }) => {
  let docsref = doc(db, "users", docID);

  const res = await updateDoc(docsref, {
    contacts: arrayUnion(payload),
  }).catch((err) => console.log(err));
  return res;
};
export const removeContactsSpecificData = async ({ docID, payload }) => {
  let docsref = doc(db, "users", docID);
  const res = await updateDoc(docsref, {
    contacts: arrayRemove(payload),
  }).catch((err) => console.log(err));
  return res;
};
export const removeContactsData = async ({ docID, phoneNumber,howMany,name,status }) => {
  const res = await axios.post("/removePerson",{docID,phoneNumber,howMany,name,status})
  console.log(res)
  return res;
};
export const getDocument = async ({ docID }) => {
  let docsref = doc(db, "users", docID);
  const res = await getDoc(docsref).catch((err) => console.log(err));
  console.log(res);
  return res;
};
export const sendResetLink = async ({ email }) => {
  const res = await sendPasswordResetEmail(auth,email)
  console.log(res)
  return res;
};