import "./Invitation.css";
import { useEffect, useState } from "react";
import FileDisplay from "../../components/FileDisplay";
import "./Invitation.css";
import {
  addContactsSpecificData,
  addDocumentData,
  addDocumentSpecificData,
  getClient,
  getDocument,
  removeContactsData,
  removeContactsSpecificData,
  sendResetLink,
} from "../../firebase";
import { useParams } from "react-router-dom";
const UnknownClient = ({ addAlertText, id }) => {
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("0522813907");
  const [status, setStatus] = useState("0522813907");
  useEffect(() => {
    sendResetLink({ email: "amitedri778@gmail.com" }).then((res) => console.log(res));
  }, []);
  const [howMany, setHowMany] = useState(1);

  const addNewClient = async (e) => {
    let status = e.target.dataset.status;
    if (!name || !phoneNumber) {
      addAlertText({ mode: "warning", text: "יש למלא את כל השדות." });
      return;
    }
    if (phoneNumber.length !== 10) {
      addAlertText({ mode: "warning", text: "יש למלא מספר תקין ללא מקפים." });
      return;
    }

    removeContactsData({ docID: id, phoneNumber, howMany, name, status })
      .then((res) => (window.location.href = "/thanks"))
      .catch((err) => console.log(err));

    return;
  };

  return (
    <div
      className="col-xxl-6 col-xl-6 col-lg-6 col-md-10 col-sm-12 col-12 d-flex flex-column border border-dark justify-content-center align-items-center mt-3 position-relative bg-light whiteShadow"
      style={{ minHeight: "200px" }}
    >
      <div className="fs-6">אנא מלאו את הפרטים</div>
      <div className="col-12 d-flex flex-column flex-wrap justify-content-center align-items-center m-2">
        <div className="col-3 fs-5 greyBlueText">שם מלא</div>
        <input className="form-control w-50 p-3" type={"text"} vlaue={howMany} onChange={(e) => setName(e.target.value)} placeholder="שם מלא..." />
      </div>
      <div className="col-12 d-flex flex-column flex-wrap justify-content-center align-items-center m-2">
        <div className="col-3 fs-5 greyBlueText">טלפון</div>
        <input className="form-control w-50 p-3" type={"text"} vlaue={howMany} onChange={(e) => setPhoneNumber(e.target.value)} placeholder="הכנס מספר..." />
      </div>
      <div className="col-12 d-flex flex-column flex-wrap justify-content-center align-items-center m-2">
        <div className="col-3 fs-5 greyBlueText">כמה אתם?</div>
        <div className="col-12 d-flex flex-row flex-wrap justify-content-center align-items-center">
        <span
            onClick={(e) => setHowMany((prev) => prev + 1)}
            className="border bg-success text-center fs-5 text-white d-flex flex-row flex-wrap justify-content-center align-items-center pointer rounded"
            style={{ height: "50px", width: "50px" }}
          >
            +
          </span>
          <span className="p-2">{howMany}</span>
          <span
            onClick={(e) => {
              if (howMany > 1) {
                setHowMany((prev) => prev - 1);
              }
            }}
            className="border bg-success text-center fs-5 text-white d-flex flex-row flex-wrap justify-content-center align-items-center pointer rounded"
            style={{ height: "50px", width: "50px" }}
          >
            -
          </span>
        </div>
      </div>
      <div className="col-12 d-flex flex-row flex-wrap justify-content-center align-items-center">
        <div onClick={(e) => addNewClient(e)} data-status="approved" className="col m-2 p-2 pointer rounded shadow-sm fs-3 text-white bg-success">
          אגיע
        </div>
        <div onClick={(e) => addNewClient(e)} data-status="notComing" className="col m-2 p-2 pointer rounded shadow-sm fs-3 text-white bg-danger">
          לא אגיע
        </div>
        <div onClick={(e) => addNewClient(e)} data-status="pending" className="col m-2 p-2 pointer rounded shadow-sm fs-3 text-white bg-warning">
          אולי
        </div>
      </div>
    </div>
  );
};
const KnownClient = ({ currentClient, id, addAlertText, ownerName, clientName }) => {
  const [howMany, setHowMany] = useState(1);

  const updateClient = async (e) => {
    let status = e.target.dataset.status;
    if (!status) {
      addAlertText({ mode: "warning", text: "יש לבחור סטאטוס הגעה לאירוע." });
      return;
    }
    await removeContactsSpecificData({ docID: id, payload: currentClient });
    currentClient.status = status;
    currentClient.howMany = howMany;
    console.log(currentClient);
    addContactsSpecificData({ docID: id, payload: currentClient }).then((res) => (window.location.href = "/thanks"));
  };

  return (
    <div
      className="col-xxl-6 col-xl-6 col-lg-6 col-md-10 col-sm-12 col-12 d-flex flex-column border border-dark justify-content-center align-items-center mt-3 position-relative bg-light whiteShadow"
      style={{ minHeight: "200px" }}
    >
      <div className="fs-5 m-2 greyBlueText">היי {clientName} הנך מוזמן לאירוע:</div>
      <div className="fs-3 m-2 greyBlueText">{ownerName}</div>
      <div className="col-12 d-flex flex-column flex-wrap justify-content-center align-items-center m-2">
        <div className="col-3 fs-5 greyBlueText">כמה אתם?</div>
        <div className="col-12 d-flex flex-row flex-wrap justify-content-center align-items-center">
          <span
            onClick={(e) => setHowMany((prev) => prev + 1)}
            className="border bg-success text-center fs-5 text-white d-flex flex-row flex-wrap justify-content-center align-items-center pointer rounded"
            style={{ height: "50px", width: "50px" }}
          >
            +
          </span>
          <span className="p-2">{howMany}</span>
          <span
            onClick={(e) => {
              if (howMany > 0) {
                setHowMany((prev) => prev - 1);
              }
            }}
            className="border bg-success text-center fs-5 text-white d-flex flex-row flex-wrap justify-content-center align-items-center pointer rounded"
            style={{ height: "50px", width: "50px" }}
          >
            -
          </span>
        </div>
      </div>
      <div className="col-12 d-flex flex-row flex-wrap justify-content-center align-items-center">
        <div onClick={(e) => updateClient(e)} data-status="approved" className="col m-2 p-2 pointer rounded shadow-sm fs-3 text-white bg-success">
          אגיע
        </div>
        <div onClick={(e) => updateClient(e)} data-status="notComing" className="col m-2 p-2 pointer rounded shadow-sm fs-3 text-white bg-danger">
          לא אגיע
        </div>
        <div onClick={(e) => updateClient(e)} data-status="pending" className="col m-2 p-2 pointer rounded shadow-sm fs-3 text-white bg-warning">
          אולי
        </div>
      </div>
    </div>
  );
};

const Invitation = ({ addAlertText }) => {
  const { id, phone } = useParams();
  const [namesFontSize, setNamesFontSize] = useState("50");
  const [namesColor, setNamesColor] = useState("#D3AF37");
  const [quouteFontSize, setQuouteFontSize] = useState("28");
  const [quouteColor, setQuouteColor] = useState("#D3AF37");
  const [dateFontSize, setDateFontSize] = useState("50");
  const [dateColor, setDateColor] = useState("#D3AF37");
  const [addressFontSize, setAddressFontSize] = useState("20");
  const [addressColor, setAddressColor] = useState("#D3AF37");
  const [parentsFontSize, setParentsFontSize] = useState("24");
  const [parentsColor, setParentsColor] = useState("#D3AF37");
  const [backgroundColor, setBackgroundColor] = useState("#FFFEFC");
  const [borderColor, setBorderColor] = useState("#263F3F");
  const [eventOwners, seteventOwners] = useState("");
  const [dedicationText, setDedicationText] = useState("");
  const [date, setDate] = useState("");
  const [day, setDay] = useState("");
  const [locationName, setLocationName] = useState("");
  const [locationAddress, setLocationAddress] = useState("");
  const [brideParentsLabel, setBrideParentsLabel] = useState("");
  const [groomParentsLabel, setGroomParentsLabel] = useState("");
  const [brideParentsNames, setBrideParentsNames] = useState("");
  const [groomParentsNames, setGroomParentsNames] = useState("");
  const [dedicationSecond, setdedicationSecond] = useState("");
  const [attachmentUrl, setAttachmentUrl] = useState("");
  const [attachmentType, setAttachmentType] = useState("");
  const [withWazeLink, setWithWazeLink] = useState(false);

  const [ownerName, setOwnerName] = useState("האירוע של שלומי ומיכל");
  const [isKnown, setIsKnown] = useState(false);
  const [currentClient, setCurrentClient] = useState({});
  const [isOn, setisOn] = useState(false);

  useEffect(() => {
    const fireAsync = async () => {
      let doc = await getDocument({ docID: id });
      let data = doc.data();
      if (data.hasOwnProperty("invitationData")) {
        setisOn(true);
        const invitationData = data.invitationData;
        setOwnerName(invitationData.eventOwners);
        setNamesFontSize(invitationData.namesFontSize);
        setNamesColor(invitationData.namesColor);
        setQuouteFontSize(invitationData.quouteFontSize);
        setQuouteColor(invitationData.quouteColor);
        setDateFontSize(invitationData.dateFontSize);
        setDateColor(invitationData.dateColor);
        setAddressFontSize(invitationData.addressFontSize);
        setAddressColor(invitationData.addressColor);
        setParentsFontSize(invitationData.parentsFontSize);
        setParentsColor(invitationData.parentsColor);
        setBackgroundColor(invitationData.backgroundColor);
        setBorderColor(invitationData.borderColor);
        seteventOwners(invitationData.eventOwners);
        setDedicationText(invitationData.dedicationText);
        setDate(invitationData.date);
        setDay(invitationData.day);
        setLocationName(invitationData.locationName);
        setLocationAddress(invitationData.locationAddress);
        setBrideParentsLabel(invitationData.brideParentsLabel);
        setGroomParentsLabel(invitationData.groomParentsLabel);
        setBrideParentsNames(invitationData.brideParentsNames);
        setGroomParentsNames(invitationData.groomParentsNames);
        setdedicationSecond(invitationData.dedicationSecond);
        setWithWazeLink(invitationData.withWazeLink);

        if (invitationData.hasOwnProperty("attachmentData")) {
          setAttachmentUrl(invitationData.attachmentData.url);
          setAttachmentType(invitationData.attachmentData.fileType);
        }

        let allContacts = await getDocument({ docID: id });
        let item = allContacts.data().contacts.filter((el) => el.phoneNumber == phone);
        if (item[0]) {
          setIsKnown(true);
          setCurrentClient(item[0]);
        }
      }

      return doc;
    };
    if (id) {
      fireAsync();
    }
  }, [id]);
  if (!isOn) {
    return (
      <div className="col-12 d-flex justify-content-center align-items-center" style={{ height: "100vh", whiteSpace: "pre-wrap" }}>
        <div class="spinner-border text-secondary m-auto" style={{ height: "100px", width: "100px" }} role="status">
          <span class="sr-only"></span>
        </div>
      </div>
    );
  }
  if (!id) {
    window.location.href = "/";
    return;
  }
  return (
    <div
      className="col-xxl-10 col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12 d-flex flex-column greyBlue position-relative justify-content-center align-items-center correntContainer"
      style={{ borderLeft: `10px solid ${borderColor}`, borderRight: `10px solid ${borderColor}`, backgroundColor: backgroundColor, overflowX: "hidden" }}
    >
      <div className="col-11 d-flex flex-row justify-content-center m-2"></div>
      {/* upload */}

      <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12 d-flex flex-column justify-content-center align-items-center  mt-2 position-relative">
        {attachmentUrl && <FileDisplay source={attachmentUrl} localState={attachmentType} setSource={setAttachmentUrl} />}
      </div>
      {/* marriage info */}
      <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12 d-flex flex-column justify-content-center align-items-center mt-2 position-relative">
        <hr className="rounded" style={{ width: "80%", backgroundColor: borderColor, height: "5px", opacity: "0.2" }} />

        <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12 d-flex flex-row  justify-content-center align-items-center">
          <div className="col names" style={{ color: `${namesColor}`, whiteSpace: "pre-wrap", whiteSpace: "pre-wrap", fontSize: `${namesFontSize}px` }}>
            {eventOwners}
          </div>
        </div>
      </div>
      <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12 d-flex flex-column justify-content-center align-items-center mt-2">
        <div className="col" style={{ color: `${quouteColor}`, fontSize: `${parseInt(quouteFontSize) - 5}px`, whiteSpace: "pre-wrap" }}>
          {dedicationText}
        </div>
      </div>
      {/* date */}
      <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12 d-flex flex-column justify-content-center align-items-center mt-2">
        <div className="col" style={{ color: `${dateColor}`, fontSize: `${dateFontSize}px`, letterSpacing: "10px", whiteSpace: "pre-wrap" }}>
          {date}
        </div>
        <div className="col" style={{ color: `${dateColor}`, fontSize: `${parseInt(dateFontSize) - 15}px`, whiteSpace: "pre-wrap" }}>
          {day}
        </div>
      </div>
      <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12 d-flex flex-column justify-content-center align-items-center">
        <div className="col" style={{ color: `${addressColor}`, fontSize: `${addressFontSize}px`, whiteSpace: "pre-wrap" }}>
          {locationName}
        </div>
        <div className="col" style={{ color: `${addressColor}`, fontSize: `${addressFontSize}px`, whiteSpace: "pre-wrap" }}>
          {locationAddress}
        </div>
      </div>
      <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12 d-flex flex-column justify-content-center align-items-center mt-3 position-relative">
        <div className="col-12 d-flex flex-row flex-wrap justify-content-center align-items-center" style={{ minHeight: "100px", whiteSpace: "pre-wrap" }}>
          <div className="col-5 d-flex flex-column justify-content-center align-items-center border-start">
            <span className=" greyBlueText " style={{ color: `${parentsColor}`, fontSize: `${parseInt(parentsFontSize) + 10}px`, whiteSpace: "pre-wrap" }}>
              {groomParentsLabel}
            </span>
            <div className="col-12 d-flex flex-row justify-content-center align-items-center">
              <div className="col" style={{ color: `${parentsColor}`, fontSize: `${parseInt(parentsFontSize) - 5}px`, whiteSpace: "pre-wrap" }}>
                {groomParentsNames}
              </div>
            </div>
          </div>
          <div className="col-5 d-flex flex-column justify-content-center align-items-center">
            <span className=" greyBlueText" style={{ color: `${parentsColor}`, fontSize: `${parseInt(parentsFontSize) + 10}px`, whiteSpace: "pre-wrap" }}>
              {brideParentsLabel}
            </span>
            <div className="col-12 d-flex flex-row justify-content-center align-items-center">
              <div className="col" style={{ color: `${parentsColor}`, fontSize: `${parseInt(parentsFontSize) - 5}px`, whiteSpace: "pre-wrap" }}>
                {brideParentsNames}
              </div>
            </div>
          </div>
        </div>

        <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12 d-flex flex-row justify-content-center align-items-center mt-2">
          <div className="col d-flex justify-content-center align-items-center flex-wrap">
            <span style={{ fontSize: `${quouteFontSize}px`, color: `${quouteColor}`, whiteSpace: "pre-wrap" }} className=" w-100 m-0 p-0">
              {dedicationSecond}
            </span>
          </div>
        </div>
        {withWazeLink && (
          <a
            className="col-6 d-flex flex-column justify-content-center align-items-center border p-2 rounded"
            onClick={() => window.open(`https://waze.to/?q=${locationAddress}&navigate=yes`)}
          >
            <span className="fs-5 greyBlueText">לחצו כאן להוראות הגעה בוייז</span>
            <img src="/assets/icons/waze.svg" height="75" width="200" />
          </a>
        )}
        {isKnown ? (
          <KnownClient addAlertText={addAlertText} currentClient={currentClient} ownerName={ownerName} clientName={currentClient?.name || ""} id={id} />
        ) : (
          <UnknownClient addAlertText={addAlertText} id={id} />
        )}
      </div>
    </div>
  );
};

export default Invitation;
