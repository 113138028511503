import { useEffect } from "react";
import { useState } from "react";
import "./Login.css";
import { auth, createUser, login } from "../../firebase";

const Login = ({ addAlertText,setDocID}) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [submit, setSubmit] = useState(false);

  useEffect(() => {
    if (submit) {
      const fireAsync = async () => {
        console.log(username, password);
        const res = await login({ password: password, username: username });
        console.log(res);
        if (res) {
          setDocID(res.user.uid)
          window.sessionStorage.setItem("login",JSON.stringify({"id":res.user.uid,isAuth:true}))
          addAlertText({ text: "התחברתם בהצלחה!", mode: "success" });
          setTimeout(() => {
            if (res.user.email === "admin@gmail.com") {
              window.location.href = "/admin";
              return;
            }
            window.location.href = "/welcome";
            return;
          }, 1500);
          
          return;
        }
        addAlertText({ text: "!!פרטי ההתחברות אינם נכונים", mode: "warning" });
      };
      fireAsync();
    }
  }, [submit]);

  return (
    <div className="col-12 d-flex flex-column justify-content-center greyBlue" style={{ height: "100vh" }}>
      <div className="col-12 d-flex flex-column justify-content-center bg-dark bg-white p-2 mainContainer">
        <div className="col-xxl-3 col-xl-3 col-lg-8 col-md-8 col-sm-10 col-10 m-auto d-flex flex-column justify-content-center align-items-center border-end border-start p-1 rounded shadow-lg">
          <div className="col">
            <img src="./assets/icons/ronenlogo.png" height="150" width="150" />
          </div>
          <hr className="" style={{ width: "80%" }} />
          <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-10 col-10 d-flex flex-column justify-content-center align-items-center m-2">
            <div className="col-12 d-flex flex-column justify-content-center align-items-center">
              <span style={{ fontSize: "14px" }} className=" align-self-center ">
                שם משתמש
              </span>
              <img src="./assets/icons/user.svg" className="" height="25" width="25" />
            </div>
            <input
              onChange={(e) => setUsername(e.target.value)}
              className="form-control border-bottom border-top-0 border-end-0 border-start-0"
              type="text"
              placeholder="הקלד כאן"
            />
          </div>
          <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-10 col-10 d-flex flex-column justify-content-center align-items-center m-2">
            <div className="col-12 d-flex flex-column justify-content-center align-items-center">
              <span style={{ fontSize: "14px" }} className=" align-self-center ">
                סיסמא
              </span>
              <img src="./assets/icons/pass.svg" className="" height="25" width="25" />
            </div>
            <input
              onChange={(e) => setPassword(e.target.value)}
              className="form-control border-bottom border-top-0 border-end-0 border-start-0"
              type="text"
              placeholder="הקלד כאן"
            />
          </div>

          <div className="d-grid col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-10 col-10 m-2">
            <div className="btn col-12 shadow-sm fw-bold shadow-sm text-white greyBlue" onClick={() => setSubmit((prev) => !prev)}>
              שלח
            </div>
          </div>
          <div className="col d-flex flex-column justify-content-center align-items-center">
            {/* <div style={{ fontSize: "12px" }} className="fw-bold pointer">
              שכחתם את הסיסמא?
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
