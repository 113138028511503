import { useEffect, useState } from "react";
import "./Alert.css";
const Alert = ({ payload }) => {
  const [textLocal, setTextLocal] = useState("");
  const [modeLocal, setModeLocal] = useState("");

  useEffect(() => {
    console.log("new payload",payload)
    setTextLocal(() => payload.text);
    setModeLocal(() => payload.mode);
    setTimeout(() => {
      setTextLocal(() => "");
      setModeLocal(() => "");
    }, 5000);
  }, [payload]);

  if (!textLocal) {
    return null;
  }
  return (
    <div
      className={`customAlert col-xxl-3 col-xl-3 col-lg-3 col-md-5 col-sm-5 col-5 bg-${payload.mode} rounded shadow-lg d-flex justify-content-center align-items-center`}
    >
      <span className="text-white fs-3">{payload.text}</span>
    </div>
  );
};

export default Alert;
