import "./Step.css";
const Step = ({ num }) => {
  return (
    <div className="col-12 d-flex flex-row m-2">
      <div className="stepContainer d-flex justify-content-center align-items-center fs-1 fw-bold greyBlue text-white shadow-lg toHide">{num}</div>
    </div>
  );
};

export default Step;
