import { useEffect, useState } from "react";
import "./Preview.css";
const PreviewBtn = ({ setSubmit, setPreview, submit,isPreview }) => {
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    let elems = document.querySelectorAll(".toHide");
    let correntContainer = document.querySelectorAll(".col");
    console.log(elems);
    if (showPreview) {
      setPreview(true);
      elems.forEach((el) => el.classList.add("d-none"));
      return;
    }
    setPreview(false);

    elems.forEach((el) => el.classList.remove("d-none"));
    return;
  }, [showPreview]);

  return (
    <div className="col-12 d-flex flex-row justify-content-center align-items-center">
      <div className="btnok whiteShadow text-white d-flex justify-content-center align-items-center pointer" onClick={() => setShowPreview((prev) => !prev)}>
        {isPreview ? "המשך לערוך" : "תצוגה מקדימה"}
      </div>
      <div className="savebtn whiteShadow text-white d-flex justify-content-center align-items-center pointer" onClick={() => setSubmit(true)}>
        {submit ? (
          <div class="spinner-border text-white m-auto" role="status">
            <span class="sr-only"></span>
          </div>
        ) : (
          "לשמור שינויים"
        )}
      </div>
    </div>
  );
};

export default PreviewBtn;
