import { useEffect, useState } from "react";
import "./App.css";
import AppRouter from "./AppRouter";
import Alert from "./components/Alert";
import { createEventUser, login } from "./firebase";
function App() {
  // qwerty123
  // admin@gmail.com

  const [alertData, setAlertData] = useState({});
  const addAlertText = (payload) => {
    let elem = document.querySelector("#toppper");
    if (elem) {
      elem.scrollIntoView();
    }
     setAlertData(() => payload);
     return
  };

  return (
    <div className="App d-flex justify-content-center">
      <div id="toppper"></div>
      <Alert payload={alertData} />
      <AppRouter addAlertText={addAlertText} />

    </div>
  );
}

export default App;
