import { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, useParams } from "react-router-dom";
import { auth } from "./firebase";
import Admin from "./Pages/Admin";
import Invitation from "./Pages/Invitation";
import Login from "./Pages/Login";
import Manager from "./Pages/Manager";
import Thanks from "./Pages/Thanks";
import Welcome from "./Pages/Welcome";
const AppRouter = ({ addAlertText }) => {
  const [isAuth, setIsAuth] = useState("");
  const [authData, setAuthData] = useState("");
  const [docID, setDocID] = useState("");

  useEffect(() => {
    let item = window.sessionStorage.getItem("login");
    if (!item) {
      return;
    }
    let parsed = JSON.parse(item)
    // setDocID(item)
    console.log("parsed.id",parsed.id)
    setDocID(parsed.id);
  }, []);
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Login addAlertText={addAlertText} docID={docID} setDocID={setDocID} />} />
        <Route exact path="/welcome" element={<Welcome />} />
        <Route exact path="/manage" element={<Manager addAlertText={addAlertText} docID={docID} />} />
        <Route exact path="/invitation/:id/:phone" element={<Invitation addAlertText={addAlertText} />} />
        <Route exact path="/invitation/:id" element={<Invitation addAlertText={addAlertText} />} />
        <Route path="/thanks" element={<Thanks />} />
        <Route path="*" element={<div>404</div>} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
