import "./Welcome.css";
const Welcome = () => {
  return (
    <div className="col-12 d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
      <div className="col-12 d-flex flex-column justify-content-center align-items-center">
        <div className="col-12 d-flex flex-row justify-content-center">
          <img src="./assets/icons/ronenlogo.png" height="120" width="120" />
        </div>
        <h1 className="greyBlueText fadeIn">היי, אנחנו מאחלים לכם המון מזל טוב</h1>
        <p className="fs-5 w-75 fadeIn">במסך הבא תוכלו להגדיר את הפרטים של האירוע שלכם ולעצב את ההזמנה בדיוק כמו שאתם רוצים.</p>
        <div className=" d-flex flex-row justify-content-center align-items-center col-xxl-3 col-xl-3 col-lg-5 col-md-5 col-sm-10 col-10 m-2 align-self-center">
          <div className="btn col-12 shadow-sm fw-bold shadow-sm text-white fadeIn localBtn" onClick={()=>window.location.href = "/manage"} style={{ backgroundColor: "#496C74" }}>
            בואו נתחיל
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
