import { useEffect, useState } from "react";

const Waze = ({ withWazeLink,setWithWazeLink }) => {

  return (
    <div
      className={`col-5 rounded d-flex flex-column justify-content-center align-items-center border p-2 shadow-sm toHide`}
    >
      <span className="">הוסף קישור לWAZE</span>
      <input onChange={(e)=>setWithWazeLink((prev)=>!prev)} class="form-check-input p-2" type="checkbox" checked={withWazeLink} />

    </div>
  );
};

export default Waze;
