import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
// Desired file extesion
const fileExtension = ".xlsx";
export const determinStatus = (value) => {
  if (value === "pending") {
    return "ממתין לתגובה";
  }
  if (value === "approved") {
    return "אישרו הגעה";
  }
  if (value === "notComing") {
    return "לא מגיעים";
  }
  if (value === "all") {
    return " לכולם";
  }
};
export const determinStatusClass = (value) => {
  if (value === "pending") {
    return "text-warning";
  }
  if (value === "approved") {
    return "text-success";
  }
  if (value === "notComing") {
    return "text-danger";
  }
  if (value === "all") {
    return "text-muted";
  }
};
export const exportToSpreadsheet = (data, fileName) => {
    //Create a new Work Sheet using the data stored in an Array of Arrays.
    let temp = [];
    data.forEach((el) => {
      if (el.name !== "") {
        temp.push(el);
      }
    });
    let translated = temp.map((el) => {
      return {
        שם: el.name,
        סטאטוס: determinStatus(el.status),
        טלפון: el.phoneNumber,
        מוזמנים: el.howMany,
      };
    });
    const workSheet = XLSX.utils.json_to_sheet(translated);
    // Generate a Work Book containing the above sheet.
    const workBook = {
      Sheets: { data: workSheet, cols: [] },
      SheetNames: ["data"],
    };
    // Exporting the file with the desired name and extension.
    const excelBuffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" });
    const fileData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(fileData, fileName + fileExtension);
  };
