import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { getDocument } from "../../firebase";
import "./Dashboard.css";
import {determinStatus,determinStatusClass,exportToSpreadsheet} from '../../Utils'
const SMSAction = ({ date, groupName }) => {
  const [isAfter, setIsAfter] = useState(false);
  useEffect(() => {
    let momentTime = moment(date);
    if (moment(new Date()).isAfter(momentTime)) {
      setIsAfter(true);
    }
  }, []);
  return (
    <div className="d-flex flex-row justify-content-center align-content-center align-items-center col-12 border-bottom m-2">
      <img src="./assets/icons/date.svg" className="col" height="35" width="35" />
      <span className="fw-bold col">{date}</span>
      <span className="fw-bold col">
        נשלח למוזמנים בסטאטוס: <span className={determinStatusClass(groupName)}>{determinStatus(groupName)}</span>
      </span>
      {isAfter ? (
        <img className="p-2 col" src="./assets/icons/check.svg" height="35" width="35" />
      ) : (
        <img className="p-2 col" src="./assets/icons/pending.svg" height="35" width="35" />
      )}
    </div>
  );
};

const Dashboard = ({ addAlertText, docID }) => {
  const [ownerName, setOwnerName] = useState("");

  const [approved, setApproved] = useState(0);
  const [notComing, setNotComing] = useState(0);
  const [pending, setPending] = useState(0);
  const [total, setTotal] = useState(0);
  const [approvedList, setApprovedList] = useState([]);
  const [notComingList, setNotComingList] = useState([]);
  const [pendingList, setPendingList] = useState([]);
  const [totalList, setTotalList] = useState([]);
  const [smsBank, setSmsBank] = useState(250);
  const [contacts, setContacts] = useState([]);
  const [modalTitle, setModalTitle] = useState({ title: "", mode: "", icon: "", list: [] });
  const [smsSchdule, setSmsSchdule] = useState([]);

  useEffect(() => {
    const fireAsync = async () => {
      let doc = await getDocument({ docID: docID });

      const data = doc.data();
      setSmsBank(data.smsBank);
      if (data.hasOwnProperty("contacts")) {
        setContacts(data.contacts);
      }
      if (data.hasOwnProperty("messages")) {
        setSmsSchdule(data.messages);
      }
      if (data.hasOwnProperty("contacts")) {
      }

      setOwnerName(data.title);
      return doc;
    };
    if (docID) {
      fireAsync();
    }
  }, [docID]);

  useEffect(() => {
    if (contacts.length !== 0) {
      let pending = [];
      let approved = [];
      let notComing = [];
      let total = [];

      contacts.forEach((el) => {
        if (el.status === "pending") {
          pending.push(el);


        }
        if (el.status === "notComing") {
          notComing.push(el);
        }
        if (el.status === "approved") {
          approved.push(el);
          let howMany = parseInt(el.howMany);
          for (let i = 0; i < howMany; i++) {
            total.push({ name: "", phoneNumber: "", howMany: "", status: "approved" });
          }
        }
      });
      setPending(pending.length);
      setNotComing(notComing.length);
      setApproved(approved.length);
      setTotal([...total].length);
      setPendingList(pending);
      setNotComingList(notComing);
      setApprovedList(approved);
      setTotalList([...total]);
    }
  }, [contacts]);
  const copyText = () => {
    navigator.clipboard.writeText(`https://invitations.flashback.co.il/invitation/${docID}`);
    addAlertText({ mode: "success", text: "הקישור הועתק בהצלחה!" });
  };
  return (
    <div className="col-10 d-flex flex-column justify-content-center align-items-center border rounded whiteShadow bg-white" style={{ minHeight: "350px" }}>
      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body col-12 d-flex flex-row justify-content-center align-items-center">
              <h1 class="modal-title col-12 fs-5" id="exampleModalLabel">
                <div className="col-12 d-flex flex-row justify-content-center align-items-center">
                  <div className={`fs-3 text-${modalTitle.mode}`}>{modalTitle.title}</div>
                  <img className="p-2" src={`./assets/icons/${modalTitle.icon}.svg`} height="35" width="35" />
                </div>
                <div className="col-12 d-flex flex-column justify-content-center align-items-center" style={{ maxHeight: "500px", overflowY: "auto" }}>
                  {modalTitle.list.map((el) => {
                    if (el.name) {
                      return (
                        <div className={`col-12 d-flex flex-row justify-content-center align-items-center border-bottom border-${modalTitle.mode}`}>
                          <div style={{ fontSize: "15px" }} className="m-1">
                            {el.name}
                          </div>
                          <div style={{ fontSize: "15px" }} className="m-1">
                            + {el.howMany}
                          </div>
                          <div style={{ fontSize: "15px" }} className="m-1">
                            {el.phoneNumber}
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              </h1>
            </div>
            <div class="modal-footer d-flex flex-column justify-content-center">
              <a onClick={() => exportToSpreadsheet(modalTitle.list, "רשימת מוזמנים")} className={`align-self-center text-${modalTitle.mode} pointer`}>
                הורד כאקסל
              </a>

              <button type="button" class="btn btn-secondary col-5" data-bs-dismiss="modal">
                סגור
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 d-flex flex-column shadow-sm">
        <span className="fs-1 ">{ownerName}</span>
      </div>

      <div className="col-auto d-flex flex-column  justify-content-center align-items-center border m-2 shadow-sm">
        <span className="fs-5 p-2 greyBlueText">רוצים לשלוח את קישור ההזמנה בעצמכם?</span>
        <span style={{ border: "2px dotted grey" }} onClick={copyText} className=" p-3 fs-5 greyWhiteHover pointer rounded shadow-sm">
          לחצו כאן להעתיק את הקישור להזמנה
        </span>
      </div>
      {/* <Donut/> */}
      <div
        // data-bs-toggle="modal"
        // data-bs-target="#exampleModal"
        // onClick={() => setModalTitle({ title: "סך הכל מוזמנים שאישרו הגעה", mode: "primary", icon: "check", list: approvedList })}
        className="col-xxl-4 col-xl-4 col-lg-5 col-md-11 col-sm-11 text-white m-1 d-flex flex-column justify-content-center align-items-center rounded border-end"
        style={{ backgroundColor: "#01D2FF" }}
      >
        <span className="p-2 fs-3" alt="" height="35" width="35">
          #
        </span>

        <span className="fs-3  m-1">סך הכל מוזמנים שיגיעו לאירוע </span>
        <hr style={{ width: "80%" }} />
        <span className="fs-3 m-1">{total}</span>
      </div>

      <div className="col-11 d-flex flex-row flex-wrap border-bottom shadow-sm text-white">
        <div
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          onClick={() => setModalTitle({ title: "אינם מגיעים", mode: "danger", icon: "out", list: notComingList })}
          className="col m-1 d-flex flex-column justify-content-center align-items-center pointer rounded border-end bg-danger"
        >
          <div className="col d-flex justify-content-center align-items-center flex-column">
            <img className="p-2" src="./assets/icons/out.svg" height="35" width="35" />
            <span className="fs-3 text-center">אנשי קשר שאינם מגיעים</span>
          </div>
          <hr style={{ width: "80%" }} />
          <span className="fs-6 text-white">לחץ כאן להורדת הרשימה</span>

          <span className="fs-3 text-center">{notComing}</span>
        </div>
        <div
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          onClick={() => setModalTitle({ title: "אישרו הגעה", mode: "success", icon: "check", list: approvedList })}
          className="col m-1 d-flex flex-column justify-content-center align-items-center pointer text-white rounded bg-success"
        >
          <div className="col col-11 d-flex justify-content-center align-items-center flex-column">
            <img className="p-2" src="./assets/icons/check.svg" height="35" width="35" />
            <span className="fs-3 text-center">אנשי קשר שיגיעו לאירוע</span>
          </div>
          <hr style={{ width: "80%" }} />
          <span className="fs-6 text-white">לחץ כאן להורדת הרשימה</span>

          <span className="fs-3 text-center">{approved}</span>
        </div>

        <div
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
          onClick={() => setModalTitle({ title: "ממתין לאישור", mode: "warning", icon: "pending", list: pendingList })}
          className="col m-1 d-flex flex-column justify-content-center align-items-center pointer rounded border-end bg-warning"
        >
          <div className="col d-flex justify-content-center align-items-center flex-column">
            <img className="p-2" src="./assets/icons/pending.svg" height="35" width="35" />

            <span className="fs-3 text-center"> לא יודעים אם יגיעו </span>
          </div>
          <hr style={{ width: "80%" }} />
          <span className="fs-6 text-white">לחץ כאן להורדת הרשימה</span>

          <span className="fs-3 text-center">{pending}</span>
        </div>
      </div>
      <div className="col-11 d-flex flex-column justify-content-center align-items-center">
        <span className="fs-3 text-center">תזכורות שנשלחו וממתינים</span>
        {smsSchdule.length > 0 ? (
          <div className="col-12 d-flex flex-column justify-content-center align-items-center">
            {smsSchdule.map((el) => {
              return <SMSAction date={el.timeToSend} key={el.timeToSend} groupName={el.toGroup} />;
            })}
          </div>
        ) : (
          "אין עדיין סבבי הודעות שיצרתם."
        )}
      </div>
      <div className="col-12 d-flex flex-column shadow-sm">
        <span className="fs-3 text-center">בנק SMS</span>
        <div className="col-12 d-flex flex-row flex-wrap justify-content-center align-items-center">
          <span className="fs-5 text-muted m-1">נותרו</span>
          <span className="fs-5 text-muted m-1">{smsBank}</span>
          <span className="fs-5 text-muted m-1">הודעות לשליחה</span>
        </div>
        <a onClick={()=>window.open("https://www.flashback.co.il/he/%D7%94%D7%96%D7%9E%D7%A0%D7%94-%D7%93%D7%99%D7%92%D7%99%D7%98%D7%9C%D7%99%D7%AA/buy-sms","_blank")} className="btn btn-lg border border-success" style={{ fontSize: "15px" }}>
          לרכישת הודעות SMS
        </a>
      </div>
    </div>
  );
};

export default Dashboard;
